<template>
  <SubPageWrapper
    width="full"
    :show-alerts="false"
  >
    <ContentBlock overflow-hidden>
      <div class="subpage__title changes-skeleton-title">
        <SlSkeletonLoader
          type="text-20"
          no-margin
        />
        <SlSkeletonLoader
          type="button"
          no-margin
        />
      </div>
      <div class="subpage__table changes-skeleton-table">
        <div class="changes-skeleton-table__row changes-skeleton-table__row--header">
          <div class="changes-skeleton-table__cell">
            <SlSkeletonLoader width="24px" />
            <SlSkeletonLoader
              type="divider"
              width="48px"
              no-margin
            />
          </div>
          <div class="changes-skeleton-table__cell">
            <SlSkeletonLoader />
            <SlSkeletonLoader
              type="divider"
              width="185px"
              no-margin
            />
          </div>
          <div class="changes-skeleton-table__cell">
            <SlSkeletonLoader />
            <SlSkeletonLoader
              type="divider"
              width="240px"
              no-margin
            />
          </div>
          <div class="changes-skeleton-table__cell changes-skeleton-table__cell--description-cell">
            <SlSkeletonLoader />
            <SlSkeletonLoader
              type="divider"
              width="100%"
              no-margin
            />
          </div>
        </div>
        <div
          v-for="i in 30"
          :key="i"
          class="changes-skeleton-table__row"
        >
          <div class="changes-skeleton-table__cell">
            <SlSkeletonLoader
              type="text-14"
              width="24px"
            />
            <SlSkeletonLoader
              type="divider"
              width="48px"
              no-margin
            />
          </div>
          <div class="changes-skeleton-table__cell">
            <SlSkeletonLoader type="text-14" />
            <SlSkeletonLoader
              type="divider"
              width="185px"
              no-margin
            />
          </div>
          <div class="changes-skeleton-table__cell">
            <SlSkeletonLoader type="text-14" />
            <SlSkeletonLoader
              type="divider"
              width="240px"
              no-margin
            />
          </div>
          <div class="changes-skeleton-table__cell changes-skeleton-table__cell--description-cell">
            <SlSkeletonLoader type="text-14" />
            <SlSkeletonLoader
              type="divider"
              width="100%"
              no-margin
            />
          </div>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
export default {
  name: 'ChangesSkeleton'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/changes-skeleton.scss";
</style>
